import React, { useState, useRef, useEffect } from 'react';
import { Play, Pause, SkipForward, SkipBack, Volume2, X, Maximize2 } from 'lucide-react';
import thumbnail from "./videothumbnail.png";

const Video = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [playbackRate, setPlaybackRate] = useState(1);
    const [rotation, setRotation] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [showControls, setShowControls] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const [isIOS] = useState(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    });
    const videoRef = useRef(null);
    const controlsTimeoutRef = useRef(null);
     const videoWrapperRef = useRef(null);


    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (!showModal) return;

            switch (e.code) {
                case 'Space':
                    e.preventDefault();
                    togglePlay();
                    break;
                case 'ArrowLeft':
                    skip(-5);
                    break;
                case 'ArrowRight':
                    skip(5);
                    break;
                case 'Escape':
                    closeModal();
                    break;
                case 'KeyF':
                    maxscreen();
                    break;
                default:
                    break;
            }
        };

        document.addEventListener('keydown', handleKeyPress);
        return () => document.removeEventListener('keydown', handleKeyPress);
    }, [showModal, isPlaying]);


    const maxscreen = () => {
        if (videoRef.current) {
          const videoElement = videoRef.current;
          if (videoElement.webkitEnterFullscreen) {
            videoElement.webkitEnterFullscreen();
          } else if (videoElement.requestFullscreen) {
            videoElement.requestFullscreen();
          } else if (videoElement.mozRequestFullScreen) {
            videoElement.mozRequestFullScreen();
          } else if (videoElement.msRequestFullscreen) {
            videoElement.msRequestFullscreen();
          }
        }
    };

    const handleMouseMove = () => {
      if (isIOS) return; // Disable control hiding on iOS

      setShowControls(true);
      if (controlsTimeoutRef.current) {
        clearTimeout(controlsTimeoutRef.current);
      }
      controlsTimeoutRef.current = setTimeout(() => {
        if (isPlaying) {
          setShowControls(false);
        }
      }, 1500);
    };

    const openModal = () => {
        setShowModal(true);
        if (videoRef.current) {
            const playPromise = videoRef.current.play();
            if (playPromise !== undefined) {
                playPromise.then(() => {
                    setIsPlaying(true);
                }).catch((error) => {
                    console.error("Error trying to play video: ", error);
                    setIsPlaying(false);
                });
            }
        }
    };

    const closeModal = () => {
        setShowModal(false);
        setRotation(0);
        if (videoRef.current) {
            videoRef.current.pause();
            setIsPlaying(false);
        }
    };

    const togglePlay = () => {
      if (videoRef.current) {
        if (videoRef.current.paused) {
          const playPromise = videoRef.current.play();
          if (playPromise !== undefined) {
            playPromise
              .then(() => {
                setIsPlaying(true);
                  if (!isIOS) {
                      setShowControls(false);
                  }
                  if (isMobile && !isIOS){
                    setTimeout(() => {
                      maxscreen();
                    }, 100);
                  }
              })
              .catch((error) => {
                  console.error("Error trying to play video: ", error);
                  setIsPlaying(false);
                  setShowControls(true);
              });
          }
        } else {
          videoRef.current.pause();
          setIsPlaying(false);
            setShowControls(true);
        }
      }
    };

    const skip = (seconds) => {
        if (videoRef.current) {
            videoRef.current.currentTime += seconds;
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const handleMouseDown = (e) => {
        setIsDragging(true);
        handleSeek(e);
    };

    const handleMousedrag = (e) => {
        if (isDragging) {
            handleSeek(e);
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

     const handleSeek = (e) => {
        if (!videoRef.current || !duration) return;
        const rect = e.currentTarget.getBoundingClientRect();
        const pos = (e.clientX - rect.left) / rect.width;
       videoRef.current.currentTime = pos * duration;
    };


      const handleTouchMove = (e) => {
        if (isDragging) {
        const touch = e.touches[0]; //Get touch object
        const rect = e.currentTarget.getBoundingClientRect();
        const pos = (touch.clientX - rect.left) / rect.width;

        if (videoRef.current){
            videoRef.current.currentTime = pos * duration;
         }
       }
    };
    
        const handleTouchStart = (e) => {
            setIsDragging(true);
             handleTouchMove(e)
        };

    const handleTouchEnd = () => {
      setIsDragging(false);
    };

    return (
        <div className="max-w-5xl mx-auto p-4">
            <div
                className="relative h-[500px] cursor-pointer group rounded-xl overflow-hidden shadow-lg"
                onClick={openModal}
            >
                <img
                    src={thumbnail}
                    alt="Video thumbnail"
                    className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 group-hover:bg-opacity-50 transition-all duration-300">
                    <div className="p-3 bg-[#fffcef]/20 bg-opacity-90 rounded-full flex items-center justify-center transform group-hover:scale-110 transition-transform duration-300">
                        <div className="w-20 h-20 bg-[#fffcef] bg-opacity-90 rounded-full flex items-center justify-center transform group-hover:scale-110 transition-transform duration-300">
                            <Play className="w-10 h-10 text-[#ad9b73] ml-1" />
                        </div>
                    </div>
                </div>
            </div>

            {showModal && (
                <div
                    className="fixed inset-0 bg-[#fffcef]/90 flex items-center justify-center z-50"
                    onMouseMove={handleMouseMove}
                    onTouchStart={handleMouseMove}
                >
                    <button
                        onClick={closeModal}
                        className="absolute top-4 right-4 text-white hover:text-gray-300 z-50 bg-black bg-opacity-50 p-2 rounded-full transition-colors duration-300"
                    >
                        <X className="w-6 h-6" />
                    </button>
                   <div className="relative w-full max-h-screen flex items-center justify-center">
                         <div className="relative max-w-6xl w-full mx-4">
                         <div
                            className="relative bg-black rounded-lg overflow-hidden transition-transform duration-300"
                                ref={videoWrapperRef}
                        >
                                <video
                                    ref={videoRef}
                                    className="w-full aspect-video cursor-pointer"
                                    onClick={togglePlay}
                                    onTimeUpdate={(e) => setCurrentTime(e.target.currentTime)}
                                    onLoadedMetadata={(e) => setDuration(e.target.duration)}
                                    src="https://d2r3u9c7j9d9om.cloudfront.net/chailintromain.mp4"
                                    playsInline
                                    poster={thumbnail}
                                    
                                >
                                    Your browser does not support the video tag.
                                </video>
                                 { showControls && (
                                    <>
                                        <div className="absolute inset-0 flex gap-16 items-center justify-around">
                                            <button
                                                 onClick={(e) => { e.stopPropagation(); skip(-5); }}
                                                className="p-2 md:hidden block hover:bg-gray-700 rounded-full transition-colors group"
                                            >
                                                <SkipBack className="w-6 h-6 text-white group-hover:text-blue-400" />
                                            </button>
                                            <button
                                                 onClick={(e) => { e.stopPropagation(); togglePlay(); }}
                                                className="p-4 bg-black bg-opacity-50 rounded-full hover:bg-opacity-70 transition-all duration-300 transform hover:scale-110"
                                            >
                                                {isPlaying ?
                                                    <Pause className="w-8 h-8 text-white" /> :
                                                    <Play className="w-8 h-8 text-white ml-1" />
                                                }
                                            </button>
                                            <button
                                                 onClick={(e) => { e.stopPropagation(); skip(5); }}
                                                className="p-2 md:hidden block hover:bg-gray-700 rounded-full transition-colors group"
                                            >
                                                <SkipForward className="w-6 h-6 text-white group-hover:text-blue-400" />
                                            </button>
                                        </div>
                                         {isPlaying && (
                                            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4">
                                                <div
                                                    className="w-full h-1.5 bg-gray-600 rounded-full cursor-pointer mb-4 relative group"
                                                    onMouseDown={handleMouseDown}
                                                    onMouseMove={handleMousedrag}
                                                      onMouseUp={handleMouseUp}
                                                      onMouseLeave={() => setIsDragging(false)}
                                                      onTouchStart = {handleTouchStart}
                                                      onTouchMove={handleTouchMove}
                                                      onTouchEnd={handleTouchEnd}
                                                >
                                                    <div
                                                        className="absolute h-full bg-[#fffcef] rounded-full group-hover:bg-[#fffcef] transition-colors"
                                                        style={{ width: `${(currentTime / duration) * 100}%` }}
                                                    />
                                                    <div
                                                        className="absolute h-3 w-3 bg-[#fffcef] rounded-full -top-[3px] -ml-1.5 opacity-0 group-hover:opacity-100 transition-opacity"
                                                        style={{ left: `${(currentTime / duration) * 100}%` }}
                                                    />
                                                </div>

                                                <div className="flex items-center justify-between">
                                                    <div className="flex items-center space-x-4">
                                                        <button
                                                              onClick={(e) => { e.stopPropagation(); togglePlay(); }}
                                                            className="p-2 hidden md:block hover:bg-gray-700 rounded-full transition-colors"
                                                        >
                                                            {isPlaying ?
                                                                <Pause className="w-6 h-6 text-white" /> :
                                                                <Play className="w-6 h-6 text-white" />
                                                            }
                                                        </button>

                                                        <button
                                                                onClick={(e) => { e.stopPropagation(); skip(-5); }}
                                                            className="p-2 hidden md:block hover:bg-gray-700 rounded-full transition-colors group"
                                                        >
                                                            <SkipBack className="w-6 h-6 text-white group-hover:text-blue-400" />
                                                        </button>

                                                        <button
                                                                onClick={(e) => { e.stopPropagation(); skip(5); }}
                                                            className="p-2 hidden md:block hover:bg-gray-700 rounded-full transition-colors group"
                                                        >
                                                            <SkipForward className="w-6 h-6 text-white group-hover:text-blue-400" />
                                                        </button>

                                                        <Volume2 className="w-6 h-6 text-white" />

                                                        <span className="text-white text-md:text-sm">
                                                            {formatTime(currentTime)} / {formatTime(duration)}
                                                        </span>
                                                    </div>

                                                    <div className="flex items-center space-x-2">
                                                        <select
                                                            value={playbackRate}
                                                            onChange={(e) => {
                                                                const rate = Number(e.target.value);
                                                                setPlaybackRate(rate);
                                                                if (videoRef.current) videoRef.current.playbackRate = rate;
                                                            }}
                                                            className="bg-gray-800 text-white rounded px-2 py-1 text-sm border border-gray-700 hover:border-gray-600 transition-colors"
                                                        >
                                                            <option value={0.5}>0.5x</option>
                                                            <option value={1}>1x</option>
                                                            <option value={1.5}>1.5x</option>
                                                            <option value={2}>2x</option>
                                                        </select>

                                                        <button
                                                            className="p-2 hover:bg-gray-700 rounded-full transition-colors"
                                                            onClick={maxscreen}
                                                        >
                                                            <Maximize2 className="w-6 h-6 text-white" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                          )}
                                        </>
                                        )}
                                    </div>
                                </div>
                            </div>
                </div>
            )}
        </div>
    );
};

export default Video;