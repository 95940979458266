import React from 'react'
import { motion } from 'framer-motion'
import { FaQuoteLeft } from "react-icons/fa";
import Amenites from './Amenites';
import line from '../line/line.svg';
import { Helmet } from 'react-helmet';
import Video from '../components/offerings/Video';
import Rera from '../components/home/Rera';



function About() {
  return (
    <div>
      {/* Hero Section */}

      <motion.div
        className="text-center max-w-4xl pt-[150px] mx-auto flex flex-col items-center"
      >

        <h1 className="text-3xl md:text-6xl font-bold yeseva text-[#ad9b73]">
          About Chail Heights
        </h1>
        <img src={line} alt="line" className="w-full h-[50px] md:h-[70px] mt-[-2px] opacity-70 ml-[-5px]" />
      </motion.div>

        <Video />

        

      <section className="relative min-h-screen overflow-hidden pb-16 px-4 sm:px-6 lg:px-8">
        
        {/* Helmet for SEO */}
        <Helmet>
          <title>About Us – Luxury Cottage & Villa in Chail, Himachal Pradesh</title>
          <meta
            name="description"
            content="Learn more about our luxury cottage and villas in Chail, Himachal. Our properties are perfect choice for your dream property in Himachal Pradesh"
          />
        </Helmet>


        <div className="container mx-auto relative z-10">
        <Rera/>
          {/* Hero Section */}
          {/* <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="text-center max-w-4xl mx-auto flex flex-col items-center"
          >

            <h1 className="text-3xl md:text-6xl font-bold yeseva text-[#ad9b73]">
              About Chail Heights
            </h1>
            <img src={line} alt="line" className="w-full h-[50px] md:h-[70px] mt-[-2px] opacity-70 ml-[-5px]" />



          </motion.div> */}

          {/* Content Section */}
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, margin: "-100px" }}
            transition={{ duration: 0.8 }}
            className="max-w-7xl mx-auto"
          >
            <div className="relative p-8 md:p-12 before:absolute before:inset-0 before:bg-gradient-to-r 
            before:from-transparent before:via-[#ad9b73]/5 before:to-transparent
            before:opacity-0 before:transition-opacity rounded-lg"
            >
              <div className="relative">
                <motion.div
                  initial={{ opacity: 0, scale: 0.8 }}
                  whileInView={{ opacity: 0.1, scale: 1 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.6 }}
                  className="absolute -top-6 -left-4 text-[#ad9b73]"
                >
                  <FaQuoteLeft className="w-12 h-12" />
                </motion.div>

                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, margin: "-100px" }}
                  variants={{
                    visible: {
                      transition: {
                        staggerChildren: 0.2
                      }
                    }
                  }}
                  className="space-y-6 text-gray-600 leading-relaxed text-lg relative text-justify z-10"
                >
                  {[0, 1, 2, 3, 4].map((index) => (
                    <motion.p
                      key={index}
                      variants={{
                        hidden: { opacity: 0, y: 20 },
                        visible: { opacity: 1, y: 0 }
                      }}
                      transition={{ duration: 0.6 }}
                    >
                      {[
                        "We are thrilled to present Chail Heights Villas, an exceptional residential community crafted by The Shubham Group. Located in the scenic landscapes of Chail, Himachal Pradesh, near the iconic Chail Palace, this exclusive gated society spans 8 acres. It features 14 independent villas in Chail, each boasting a generous Complementing these elegant villas are 48 1BHK ultra luxurious apartments each spanning.",
                        "Chail Heights Villas redefines residential living in Chail, Himachal Pradesh, offering a blend of modern comforts and natural beauty. Each villa in Chail, Himachal Pradesh, combines comfort and sophistication, making it perfect for those looking for a peaceful yet upscale lifestyle. Our 1 BHK apartments in Chail are designed with style and practicality, appealing to those with refined preferences.",
                        "The Shubham Group, renowned for delivering high-quality residential projects in Uttarakhand and Himachal Pradesh, proudly adds this project to its impressive portfolio. With a proven track record of excellence, our properties in Chail, Himachal, are a testament to our dedication to creating homes that offer unparalleled living experiences.",
                        "Whether you are looking for a villa, a cottage, or a luxury apartment in Chail, Himachal, Chail Heights promises to meet your expectations. Our properties in Chail provide the perfect retreat, offering a peaceful escape from the hustle and bustle of city life. These cottages in Himachal are not just houses; they are homes designed to provide comfort, luxury, and a connection to nature.",
                        "Explore the finest houses and flats in Chail with Chail Heights Villas. Invest in a property in Chail, Himachal, and experience the best that this beautiful region has to offer."
                      ][index]}
                    </motion.p>
                  ))}
                </motion.div>
              </div>
            </div>
          </motion.div>
        </div>
        <Amenites />

        <div className="container mx-auto relative z-10">
          {/* Hero Section */}
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="text-center max-w-4xl mt-20 mx-auto flex flex-col items-center"
          >

            <h1 className="text-3xl md:text-4xl font-bold yeseva text-[#ad9b73] ">
              Living in Chail, Himachal Pradesh
            </h1>

            <img src={line} alt="line" className="w-full h-[50px] md:h-[70px] mt-[-2px] opacity-70 ml-[-5px]" />

          </motion.div>

          {/* Content Section */}
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, margin: "-100px" }}
            transition={{ duration: 0.8 }}
            className="max-w-7xl mx-auto"
          >
            <div className="relative p-8 md:p-12 before:absolute before:inset-0 before:bg-gradient-to-r 
            before:from-transparent before:via-[#ad9b73]/5 before:to-transparent
            before:opacity-0 before:transition-opacity rounded-lg"
            >
              <div className="relative">
                <motion.div
                  initial={{ opacity: 0, scale: 0.8 }}
                  whileInView={{ opacity: 0.1, scale: 1 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.6 }}
                  className="absolute -top-6 -left-4 text-[#ad9b73]"
                >
                  <FaQuoteLeft className="w-12 h-12" />
                </motion.div>

                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, margin: "-100px" }}
                  variants={{
                    visible: {
                      transition: {
                        staggerChildren: 0.2
                      }
                    }
                  }}
                  className="space-y-6 text-gray-600 leading-relaxed text-lg relative text-justify z-10"
                >
                  {[0, 1, 2, 3, 4, 5].map((index) => (
                    <motion.p
                      key={index}
                      variants={{
                        hidden: { opacity: 0, y: 20 },
                        visible: { opacity: 1, y: 0 }
                      }}
                      transition={{ duration: 0.6 }}
                    >
                      {[
                        "Chail offers a unique blend of natural beauty, peace, and cultural richness. Situated amidst the Himalayan foothills, Chail is an undiscovered spot with a low population, located at a distance of 63 kms from the Chail Wildlife Sanctuary. Away from the hustle and bustle of Shimla and Solan, Chail enjoys an amazing weather year-round and offers mesmerizing views of the Himalayas and the valley.",
                        "Chail is renowned for its picturesque landscapes, lush forests, and pleasant climate throughout the year. The serene environment provides residents with clean air, breathtaking mountain views, and a sense of calm that is hard to find elsewhere. Outdoor enthusiasts in Chail have ample opportunities for activities such as trekking, nature walks, bird watching, and camping in the nearby forests, thanks to the pleasant climate that makes outdoor activities enjoyable year-round.",
                        "The town boasts a rich cultural heritage with historical landmarks like the Chail Palace and the Chail Cricket Ground, the highest cricket ground in the world. These landmarks add to the charm and historical significance of the region. Whether you seek a cottage or apartment in Chail, Himachal Pradesh, you'll find residences that blend seamlessly into the natural beauty of the area.",
                        "The tight-knit community in Chail fosters a warm and welcoming atmosphere, with residents actively participating in local festivals, cultural events, and community gatherings, fostering a strong sense of belonging and camaraderie. Chail Heights also offers flats and cottages in Chail, Himachal Pradesh, providing options for those looking to experience luxury living or a cozy retreat amidst the hills.",
                        "Despite its serene environment, Chail offers modern amenities including good connectivity with Wi-Fi zones, and recreational facilities such as clubs, gyms, and parks, enhancing the overall quality of life. Whether you prefer a house or an apartment in Chail, Himachal Pradesh, the town is an ideal destination for those seeking a peaceful and fulfilling lifestyle amidst the Himalayas.",
                        "Invest in a property at moderate rates in Chail, Himachal Pradesh, and enjoy the beauty of this undiscovered gem. Experience living amidst the Himalayas with easy access to Shimla (46 kms, 1 hour drive), Chandigarh (106 kms, 2.5 hours drive), Kalka Railway Station (81 kms), and Simla Airport (60.6 kms, 1.58 hours drive)."
                      ][index]}
                    </motion.p>
                  ))}
                </motion.div>
              </div>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
}

export default About;