import React, { useRef, useEffect, useState } from 'react';
import { motion, useInView } from 'framer-motion';
import line from '../../line/line.svg';
import Form from "../landing/Form"


function Offerings() {
    let a = 0
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, margin: "-100px" });
    const [showPopup, setShowPopup] = useState(false);
    const [scrollProgress, setScrollProgress] = useState(0);


    useEffect(() => {

        const handleScroll = () => {
            const scrolled = window.scrollY;
            const maxHeight = document.documentElement.scrollHeight;
            const progress = (scrolled / maxHeight) * 100;
            setScrollProgress(progress);
            if (a < 1) {
                if (scrolled) {
                    setShowPopup(true);
                    a++
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            {showPopup && (
                <div className="fixed top-0 z-50 p-5 left-0 w-full flex justify-center items-center h-full bg-white bg-opacity-50">

                    <Form showPopup={showPopup} setShowPopup={setShowPopup} />
                </div>
            )
            }

            {/* PHASE 1 */}
            <section ref={ref} className="relative mb-12 overflow-hidden">

                <motion.div
                    className="max-w-5xl mx-auto px-6 relative"
                    style={{
                        transform: isInView ? "none" : "translateY(100px)",
                        opacity: isInView ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
                    }}
                >
                    <div className="text-center">
                        <h2 className="text-4xl md:text-6xl yeseva text-[#ad9b73]">
                            Phase 1
                        </h2>
                        <img src={line} alt="line" className="w-full h-[50px] mt-[-2px] opacity-70 ml-[-5px] mb-3" />

                        {/* <p className='text-2xl yeseva text-gray-600 text-center'>
                            Introducing
                        </p> */}
                        <p className='yeseva mt-3 font-normal text-[#ad9b73] text-4xl'>Two Blocks of Premium Apartments</p>

                        <div className="space-y-6 max-w-3xl mx-auto">
                        </div>
                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.8 }}
                            className="mt-8"
                        >
                            <h2 className="text-5xl text-center font-bold text-[#ad9b73] yeseva mt-12">Buildings Layout</h2>
                            <img src={line} alt="line" className="w-full h-[50px] opacity-70 mb-8 ml-[-5px]" />


                            <div className="flex flex-col md:flex-row gap-5">
                                <img src={"https://d2r3u9c7j9d9om.cloudfront.net/apartment/apartmentview.jpg"} alt="Building Layout" className="w-full md:w-1/2 h-auto rounded-lg shadow-lg" />
                                <img src={"https://d2r3u9c7j9d9om.cloudfront.net/apartment/apartmentview2.jpg"} alt="Building Layout" className="w-full md:w-1/2 h-auto rounded-lg shadow-lg" />
                            </div>
                        </motion.div>
                        <div>
                            <motion.div
                                initial={{ opacity: 0, y: 50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ duration: 0.8 }}
                                className="mt-8"
                            >
                                <h2 className="text-5xl font-bold text-[#ad9b73] text-center yeseva mt-16">Floor Layout</h2>
                                <img src={line} alt="line" className="w-full h-[50px] opacity-70 ml-[-5px]" />


                                <img src={"https://d2r3u9c7j9d9om.cloudfront.net/apartment/apartmentplan.jpg"} alt="Building Layout" className="w-full h-auto mt-12 rounded-lg shadow-lg" />
                            </motion.div>
                        </div>
                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.8 }}
                            className="mt-8"
                        >
                            <h2 className="text-4xl font-bold text-[#ad9b73] yeseva mt-16 text-center">Apartment Sizes</h2>
                            <img src={line} alt="line" className="w-full h-[50px] opacity-70 mb-4 ml-[-5px]" />
                            <ul className="flex flex-col md:flex-row gap-8">

                                <p className="w-full md:w-1/2">
                                    <li className="mb-3 text-center text-[#ad9b73] "><span className='text-xl yeseva text-gray-700 font-bold'>855 sq.ft.</span><br />For a more compact yet cozy layout.</li>
                                    <img src={"https://d2r3u9c7j9d9om.cloudfront.net/apartment/floorplan2.jpg"} alt="Apartment Size Options" className="w-full h-auto object-contain" />
                                </p>
                                <p className="w-full md:w-1/2">
                                    <li className="mb-3 text-center text-[#ad9b73] "><span className='text-xl yeseva text-gray-700 font-bold'>977 sq.ft.</span><br />For those seeking expansive living spaces.</li>
                                    <img src={"https://d2r3u9c7j9d9om.cloudfront.net/apartment/floorplan.jpg"} alt="Apartment Size Options" className="w-full h-auto object-contain" />
                                </p>

                            </ul>
                        </motion.div>
                    </div>
                </motion.div>
            </section>
            <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.8 }}
                            className="mt-8 flex flex-col items-center justify-center mb-24"
                        >
                            <h2 className=" text-3xl md:text-5xl max-w-6xl text-center text-[#ad9b73] yeseva mb-4 mt-8">Early Mover Advantage</h2>
                            <h2 className="text-xl md:text-3xl max-w-6xl text-center text-[#ad9b73] ">Take your choice of Premium Located Apartments</h2>
                            <img src={line} alt="line" className="w-full h-[60px] opacity-70 ml-[-5px]" />


                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.8 }}
                            className="mt-8 flex flex-col items-center justify-center mb-24"
                        >
                            <h2 className=" text-3xl md:text-5xl max-w-6xl text-center text-[#ad9b73] yeseva mb-4 mt-8">Pricelist & Payment Plan</h2>
                            <img src={line} alt="line" className="w-full h-[60px] opacity-70 ml-[-5px]" />

                            <img src="https://d2r3u9c7j9d9om.cloudfront.net/CHV-PAYMENT-PLAN.jpg" alt=''/>


                        </motion.div>

                <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.8 }}
                            className="mt-8 flex flex-col items-center justify-center md:mb-24"
                        >
                            <h2 className="text-3xl md:text-5xl max-w-9xl text-center text-[#ad9b73] yeseva mt-12">Possessions will be starting from Spring 2026</h2>
                            <img src={line} alt="line" className="w-full h-[60px] opacity-70 mb-8 ml-[-5px]" />

                        </motion.div>

            <section className="relative mb-12 mx-8 overflow-hidden">

                <motion.div
                    className="w-full mx-auto flex justify-center items-center rounded-2xl relative"
                    style={{
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                        backgroundImage: `url('https://img.freepik.com/premium-photo/curral-das-freiras-is-civil-parish-portuguese-archipelago-madeira_78361-7473.jpg?w=1480')`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                    }}
                >
                    <div className='h-[70vh] w-full flex flex-col bg-black/40 items-center rounded-2xl'>
                        <motion.div
                            className=" w-full h-full flex flex-col items-center justify-center text-white "
                            style={{
                                transform: isInView ? "none" : "translateY(100px)",
                                opacity: isInView ? 1 : 0,
                                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                            }}
                        >
                            <p className='md:text-6xl text-5xl yeseva'>Phase 2</p>
                            <p className='md:text-6xl text-5xl my-5 text-center yeseva'>INDEPENDANT COTTAGES</p>
                            <p className='md:text-4xl text-5xl yeseva'>Coming Soon</p>
                        </motion.div>
                    </div>

                </motion.div>
            </section>

        </div>
    )
}

export default Offerings