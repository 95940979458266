import React from 'react'
import line from '../../line/line.svg';
import { motion } from 'framer-motion';

function Duplexvilla() {
    return (
        <div className="relative min-h-screen flex flex-col items-center justify-center overflow-hidden py-16 px-4 sm:px-6 lg:px-8">
            {/* <div className="absolute inset-0 bg-gradient-to-b from-transparent via-[#80702F]/5 to-transparent opacity-50" /> */}

            <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
                className="text-center max-w-4xl mx-auto flex flex-col items-center"
            >
                <h1 className="text-5xl md:text-7xl font-bold yeseva text-[#ad9b73]">
                    Phase 2
                </h1>
                <img src={line} alt="line" className="w-full h-[70px] mt-[-2px] opacity-70 ml-[-5px]" />
            </motion.div>
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
                className="mt-8 max-w-4xl mx-auto text-justify text-gray-700 space-y-6 text-lg"
            >
                <p className='text-3xl yeseva text-center'>
                    Coming Soon
                </p>
            </motion.div>
        </div>
    )
}

export default Duplexvilla