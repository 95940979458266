import React, { useRef, useEffect, useState } from 'react';
import { motion, useScroll, useTransform, AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';

function Header() {
  const containerRef = useRef(null);
  const { scrollY } = useScroll();
  const [isVisible, setIsVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const location = useLocation();
  const play = location.pathname !== "/landing";
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    let timer1, timer2;
  
    // Set animate to true after 2.5 seconds
    timer1 = setTimeout(() => {
      setAnimate(true);
  
      // Set animate to false after 2 seconds
      timer2 = setTimeout(() => {
        setAnimate(false);
      }, 2000);
    }, 2500);
  
    // Cleanup both timers
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);
  

  const opacity = useTransform(scrollY, [0, 300], [1, 0.3]);

  useEffect(() => {
    // First trigger the reveal animation
    setIsVisible(true);

    // Then trigger the expand animation after reveal
    const expandTimer = setTimeout(() => {
      setIsExpanded(true);
    }, 0); // Delayed to allow reveal animation to complete

    return () => clearTimeout(expandTimer);
  }, []);

  return (
    <div
      ref={containerRef}
      className="relative flex justify-center md:mt-10 items-center h-screen w-full overflow-hidden "
    >
      {/* Video Container */}
      <AnimatePresence>
        <motion.div
          layout
          initial={{
            marginTop: '100px',
            width: play ? '80%' : '100%',
            height: play ? '60vh' : '100vh',
            borderRadius: '20px',
            y: play ? '100%' : 0, // Apply animation only if play is true
            opacity: play ? 0 : 1
          }}
          animate={{
            width: play ? (isExpanded ? '100%' : '80%') : '100%',
            height: play ? (isExpanded ? '100vh' : '60vh') : '100vh',
            borderRadius: play ? (isExpanded ? '0px' : '20px') : '0px',
            y: play ? (isVisible ? 0 : '100%') : 0,
            opacity: play ? (isVisible ? 1 : 0) : 1
          }}
          transition={{
            y: play
              ? {
                duration: 1.2,
                ease: [0.6, 0.01, -0.05, 0.9]
              }
              : {},
            opacity: play
              ? {
                duration: 1
              }
              : {},
            width: play
              ? {
                delay: 1.2,
                duration: 1.2,
                ease: [0.6, 0.01, -0.05, 0.9]
              }
              : {},
            height: play
              ? {
                delay: 1.2,
                duration: 1.2,
                ease: [0.6, 0.01, -0.05, 0.9]
              }
              : {},
            borderRadius: play
              ? {
                delay: 1.2,
                duration: 1.2,
                ease: [0.6, 0.01, -0.05, 0.9]
              }
              : {}
          }}
          className="relative mx-auto bg-black/50 overflow-hidden"
        >


          <motion.div
            style={{ opacity }}
            className="w-full h-full sm:block hidden aspect-video"
          >
            <div className="absolute inset-0 bg-black/15 z-[1]" />
            <video
              autoPlay
              loop
              muted
              playsInline
              className="w-full h-full object-cover "
            >
              <source src="https://d2r3u9c7j9d9om.cloudfront.net/chailvideolandscape.mp4" type="video/mp4" />
            </video>
          </motion.div>
          <motion.div
            style={{ opacity }}
            className="w-full h-full block sm:hidden aspect-video"
          >
            <div className="absolute inset-0 bg-black/10 z-[1]" />
            <video
              autoPlay
              loop
              muted
              playsInline
              className="w-full h-full object-cover "
            >
              <source src="https://d2r3u9c7j9d9om.cloudfront.net/chailvideoportrait.mp4" type="video/mp4" />
            </video>
          </motion.div>
        </motion.div>
      </AnimatePresence>

      {/* Scroll Indicator */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: isExpanded ? 1 : 0 }}
        transition={{ delay: 3.5, duration: 1 }}
        className="absolute bottom-16 left-1/2 -translate-x-1/2 z-20 text-white flex flex-col items-center gap-2"
      >
        <span className="text-sm tracking-wider uppercase opacity-70">Scroll</span>
        <div className="w-6 h-10 border-2 border-white/50 rounded-full p-1">
          <motion.div
            animate={{
              y: [0, 12, 0],
            }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              ease: "easeInOut"
            }}
            className="w-2 h-2 bg-white rounded-full mx-auto"
          />
        </div>
      </motion.div>
      <div className=" absolute right-10 md:right-16 z-40 bottom-48 md:bottom-16">
                  <div
                    className="text-2xl md:text-[120px] font-bold"
                    style={{
                      transform: animate ? 'translateY(0)' : 'translateY(100%)',
                      opacity: animate ? 1 : 0,
                      transition: 'all 1s cubic-bezier(0.4, 0, 0.2, 1)',
                      transitionDelay: '0ms',
                    }}
                  >
                    <span className="text-white text-2xl">
                      RERA:- HPRERASHI2025123/P
                    </span>
                  </div>
                </div>
    </div>
  );
}

export default Header;