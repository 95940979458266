import React, { useRef } from 'react';
import { motion, useScroll, useTransform, useSpring } from 'framer-motion';
import line from '../../line/line.svg';

function ProjectHighlights() {
  const containerRef = useRef(null);
  
  const highlights = [
    {
      title: "Sprawling 15-acre Property",
      subtitle: "NATURE'S EMBRACE",
      description: "This project is on a vast 15-acre expanse, offering ample space for thoughtfully designed landscapes, open areas, and premium amenities.",
      image: "https://imgs.landbrokermls.com/public/property/1227456/1227456_4093c96b-bc50-4a6f-b8ba-a46bf214bef6.webp"
    },
    {
      title: "Shivalik Views",
      subtitle: "MAJESTIC PEAKS",
      description: "Enjoy the captivating vistas of the Shivalik range, offering breathtaking scenery and a peaceful ambiance.",
      image: "https://live.staticflickr.com/8372/8543997911_b549c42491_b.jpg"
    },
    {
      title: "Breathtaking Altitude",
      subtitle: "ELEVATED LIVING",
      description: "Situated at 7,400 feet above sea level, the project offers a tranquil retreat with refreshing mountain air and stunning views.",
      image: "https://images.pexels.com/photos/15389321/pexels-photo-15389321/free-photo-of-a-view-of-the-mountains-from-a-high-altitude.jpeg"
    },
    {
      title: "Exclusive Access",
      subtitle: "PRIVATE JOURNEY",
      description: "The project features a dedicated private road, ensuring secure and seamless connectivity. ",
      image: "https://grandfather.com/wp-content/uploads/2022/11/faq-grandfather.jpg"
    }
  ];

  return (
    <div ref={containerRef} className="relative ">
      {/* Enhanced Intro Section */}
      <motion.div 
        className="h-screen flex items-center justify-center sticky top-0 z-10 overflow-hidden"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.2, ease: "easeOut" }}
      >
        <div className="text-center px-4  p-12 rounded-2xl">
          <motion.p 
            className="text-[#ad9b73] tracking-[0.4em] text-sm mb-6 font-light"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.7 }}
          >
            DISCOVER OUR
          </motion.p>
          <motion.h1 
            className="text-5xl md:text-6xl text-[#ad9b73] yeseva font-bold"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.9, duration: 1.2 }}
          >
            Project Highlights
          </motion.h1>
            <img src={line} alt="decorative line" className="w-full h-[50px] md:h-[60px] mt-[-5px] opacity-80" />
        </div>
      </motion.div>

      {/* Enhanced Feature Sections */}
      <div className="relative z-20">
        {highlights.map((highlight, index) => (
          <FeatureSection 
            key={index}
            data={highlight}
            index={index}
          />
        ))}
      </div>
    </div>
  );
}

function FeatureSection({ data, index }) {
  const sectionRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ["start end", "end start"]
  });

  const scale = useTransform(scrollYProgress, [0, 0.5], [1.1, 1]);
  const opacity = useTransform(scrollYProgress, [0, 0.2, 0.9], [0, 1, 1]);
  const textY = useTransform(scrollYProgress, [0, 1], [100, -100]);
  const imageY = useTransform(scrollYProgress, [0, 1], [0, 100]);
  
  const springTextY = useSpring(textY, { mass: 0.5, stiffness: 100, damping: 30 });

  return (
    <motion.div
      ref={sectionRef}
      className=" h-screen sticky top-0 overflow-hidden flex items-center justify-center"
      style={{ opacity }}
    >
      {/* Background Image - reduced width and added margin */}
      <motion.div 
        className="absolute inset-0 w-[85%] mx-auto h-[70%] my-auto rounded-[1rem]"
        style={{ y: imageY }}
      >
        <motion.div
          className="relative w-full h-full rounded-[1rem]"
          style={{ scale }}
        >
          <div className="absolute inset-0 rounded-[1rem] bg-black/20" />
          <img
            src={data.image}
            alt={data.title}
            className="w-full h-full object-cover rounded-[1rem]"
          />
        </motion.div>
      </motion.div>

      {/* Content Overlay - adjusted for new positioning */}
      <div className="relative flex items-center justify-center px-4 h-full w-full z-10">
        <motion.div 
          className="max-w-3xl text-center md:p-0 p-8 mt-[20%] md:mt-[10%]"
          style={{ y: springTextY }}
        >
          <motion.p 
            className="text-[#fffcef] tracking-[0.4em] text-sm mb-6 font-light"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            {data.subtitle}
          </motion.p>
          
          <motion.h2 
            className="text-4xl md:text-7xl text-[#fffcef] px-3 yeseva font-bold mb-8"
            initial={{ opacity: 0, y: 40 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
          >
            {data.title}
          </motion.h2>
          
          <motion.p 
            className="text-lg md:text-2xl text-white/90 px-3 max-w-3xl mx-auto leading-relaxed"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.7 }}
          >
            {data.description}
          </motion.p>

          <motion.div
            className="h-[2px] w-32 bg-[#ad9b73] mx-auto mt-10"
            initial={{ width: 0 }}
            whileInView={{ width: 128 }}
            transition={{ delay: 0.9, duration: 1 }}
          />
        </motion.div>
      </div>
    </motion.div>
  );
}

export default ProjectHighlights;