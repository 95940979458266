

const GalleryImage = ({ image, index, onImageSelect }) => (
  <div
    className="relative overflow-hidden bg-white cursor-pointer dark:bg-neutral-900 rounded-2xl"
    onClick={() => onImageSelect(image, index)}
  >
    <div className="relative aspect-video">
      <img
        src={image.url}
        alt={image.title}
        className="w-full h-full object-cover"
        loading="lazy"
      />
      <div className="absolute inset-0 bg-black/50 opacity-0 hover:opacity-100 transition-opacity">
        <div className="absolute bottom-0 left-0 right-0 p-4">
          <h3 className="text-white text-lg font-medium truncate mb-2">
            {image.title}
          </h3>
          <button 
            className="px-4 py-2 bg-white rounded-lg text-black text-sm font-medium"
            onClick={(e) => {
              e.stopPropagation();
              onImageSelect(image, index);
            }}
          >
            View
          </button>
        </div>
      </div>
    </div>
  </div>
);

const GalleryGrid = ({ images, onImageSelect }) => (
  <div className="px-4 md:px-6">
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 max-w-8xl mx-auto">
      {images.map((image, index) => (
        <GalleryImage
          key={image.id}
          image={image}
          index={index}
          onImageSelect={onImageSelect}
        />
      ))}
    </div>
  </div>
);

export default GalleryGrid; 