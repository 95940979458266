import React from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import line from '../../line/line.svg';


const Rera = () => {
    const containerRef = React.useRef(null);
    const { scrollYProgress } = useScroll({
        target: containerRef,
        offset: ["start end", "end start"]
    });

    const scale = useTransform(scrollYProgress, [0, 0.2], [0.2, 1]);
    const opacity = useTransform(scrollYProgress, [0, 0], [0, 1]);
    // const rotate = useTransform(scrollYProgress, [0, 0.5], [45, 0]);

    return (
        <div ref={containerRef} className="bg-transparent max-w-7xl h-auto mx-auto flex justify-center relative">
            {/* Header */}


            {/* Blooming Images Container */}
            <div className="flex flex-col md:w-[70%] md:flex-row mx-auto justify-center gap-8 px-4">
                {/* Left Image */}
                <motion.div
                    className="relative w-full md:w-1/2 flex  md:h-[80vh] group"
                    style={{ scale, opacity }}
                >
                    <a href="https://d2r3u9c7j9d9om.cloudfront.net/ReraCertificate.pdf"
                        target='_blank'
                        rel='noreferrer'>
                        <motion.div className="w-full h-full overflow-hidden rounded-2xl">
                            <img
                                src="https://d2r3u9c7j9d9om.cloudfront.net/reracertificate.jpg"
                                alt="Project Feature 1"
                                className="w-full h-full object-contain transform transition-transform duration-700"
                            />
                        </motion.div>
                    </a>
                </motion.div>

                {/* Right Image */}
                <motion.div
                    className="relative w-full md:w-1/2 h-full pt-8 group"
                    style={{ scale, opacity }}
                >
                    <div>
                        <motion.div
                            className=" overflow-hidden  "
                        >
                            <motion.div
                                className="md:text-start md:flex text-center"
                                initial={{ opacity: 0, y: -20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.8 }}
                            >
                                <div className='flex flex-col items-center'>
                                    <h1 className="text-5xl md:text-5xl font-bold text-[#ad9b73] yeseva">Rera Certified</h1>
                                    <img src={line} alt="decorative line" className="h-[50px] w-full md:w-auto mt-[-5px] mb-5 opacity-80 object-left" />
                                </div>
                            </motion.div>

                            <motion.div
                                className="bottom-0 left-0 right-0 mb-3 text-white"
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.5 }}
                            >
                                <h3 className="text-2xl text-[#ad9b73] md:text-start text-center yeseva">TRANSPARENCY</h3>

                            </motion.div>
                            <motion.div
                                className="bottom-0 left-0 flex w-full justify-cente  right-0 mb-4 max-w-lg text-justify text-white"
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.5 }}
                            >
                                <h3 className="text-md text-[#ad9b73] yeseva mb-3">Our legal team ensures complete transparency in every
                                    transaction, giving you the peace of mind that comes
                                    with knowing your investment is secure. We are RERA
                                    approved and strictly adhere to the standards.</h3>
                            </motion.div>
                        </motion.div>
                        <motion.div
                            className="overflow-hidden rounded-2xl"
                        >
                            <motion.div
                                className="bottom-0 left-0 right-0 mb-3 text-white"
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.5 }}
                            >
                                <h3 className="text-2xl md:text-start text-center text-[#ad9b73] yeseva">FREEHOLD HOME</h3>
                            </motion.div>
                            <motion.div
                                className="bottom-0 left-0 right-0 max-w-lg text-justify text-white"
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.5 }}
                            >
                                <h3 className="text-md text-[#ad9b73] yeseva mb-3">Owning a freehold property at Chail Height Villa provides
                                    you with complete ownership rights, allowing you to pass
                                    down a legacy for generations to come.</h3>
                            </motion.div>
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.5 }}
                                className="rounded-lg p-6  text-center space-y-4"
                            >
                                <a
                                    href='https://d2r3u9c7j9d9om.cloudfront.net/ReraCertificate.pdf'
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <button

                                        className="bg-gradient-to-r from-[#ad9b73] to-[#80702F] text-white px-4 py-2 rounded-lg"
                                    >
                                        RERA Certificate
                                    </button>
                                </a>
                            </motion.div>
                        </motion.div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default Rera;