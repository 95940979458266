import React from 'react';
import { motion } from 'framer-motion';

function Notfound() {
  return (
    <section className="min-h-screen flex items-center justify-center bg-transparent p-4">
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ type: 'spring', stiffness: 100 }}
        className="text-center text-[#ad9b73] p-8 bg-transparent rounded-lg space-y-4"
      >
        <h1 className="text-5xl md:text-8xl  yeseva font-bold">404</h1>
        <h2 className="text-lg md:text-3xl font-semibold">Oops! Page Not Found</h2>
        <p className="text-sm md:text-lg max-w-3xl text-[#ad9b73]">
          We can't seem to find the page you're looking for. Please check the URL or go back to the homepage.
        </p>
        <a
          href="/"
          className="inline-block mt-6 bg-gradient-to-r from-[#ad9b73] to-[#80702F] text-white py-3 px-6 rounded-lg text-sm hover:bg-[#80702F] transition-all duration-300"
        >
          Go Back Home
        </a>
      </motion.div>
    </section>
  );
}

export default Notfound;
