import React from 'react';
import { motion } from 'framer-motion';
import { Mail, Building2, Building,} from 'lucide-react';
import rera from "../line/reraicon.svg"

function Footer() {
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2
            }
        }
    };

    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.8,
                ease: "easeOut"
            }
        }
    };

    return (
        <footer className="bg-gradient-to-b from-[#fff9e0] to-[#fff5cf] text-gray-800 relative overflow-hidden">
            {/* Decorative Elements */}
            <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-transparent via-[#ad8a56] to-transparent opacity-30" />
            <div className="absolute top-0 right-0 w-32 h-32 bg-[#ad8a56] opacity-5 rounded-full transform translate-x-16 -translate-y-16" />

            <div className="max-w-7xl mx-auto px-4 pt-5">
                <motion.div
                    className="grid grid-cols-1 md:grid-cols-4 gap-12 items-center"
                    variants={containerVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                >
                    {/* Logo & Social Section */}
                    <motion.div variants={itemVariants} className=" flex flex-col justify-center items-center">
                        <a href='https://theshubhamgroup.com' target='_blank' rel='noopener noreferrer'>
                            <img
                            src="https://d2r3u9c7j9d9om.cloudfront.net/footerlogobold.png"
                            alt="The Shubham Group Logo"
                            className="h-[180px] object-contain hover:scale-105 transition-transform duration-300"
                        />
                        </a>

                        <div className="flex -ml-5 items-center space-x-2 ">
                            <div className="p-2 rounded-full bg-[#ad8a56] bg-opacity-10 h-8 w-8">
                                <img src={rera} alt="reraicon" className="text-[#ad8a56] "/>
                            </div>
                            <h4 className="text-lg font-semibold">RERA</h4>
                        </div>
                        <div className="mt-3">
                            <a
                                href='https://hprera.nic.in/PublicDashboard'
                                target='_blank'
                                rel="noreferrer" 
                                className="block text-center md:text-start cursor-pointer hover:text-[#ad8a56] transition-all duration-300 transform hover:translate-x-2"
                            >
                                HPRERASHI2025123/P
                            </a>
                        </div>
                        
                    </motion.div>

                    {/* Registered Office */}
                    <motion.div variants={itemVariants} className="space-y-4 flex md:block flex-col items-center">
                        <div className="flex items-center space-x-2 mb-0 md:mb-6">
                            <div className="p-2 rounded-full bg-[#ad8a56] bg-opacity-10">
                                <Building2 className="text-[#ad8a56]" size={20} />
                            </div>
                            <h4 className="text-lg font-semibold">Site Address</h4>
                        </div>
                        <div
                            // href="https://maps.google.com/?q=A-24,+Okhla+Industrial+Area,+Phase-2,+Delhi,+110020"
                            // target="_blank"
                            // rel="noopener noreferrer"
                            className="block text-center md:text-start text-gray-600 cursor-pointer space-y-2 hover:text-[#ad8a56] transition-all duration-300 transform hover:translate-x-2"
                        >
                            <p className="font-medium">Deotla Road,</p>
                            <p>Himachal Pradesh, Pincode - 173217</p>
                            <p>near Chail Bus Stand,</p>
                        </div>
                    </motion.div>

                    {/* Corporate Office */}
                    <motion.div variants={itemVariants} className="space-y-4 flex md:block flex-col items-center">
                        <div className="flex items-center space-x-2 mb-0 md:mb-6">
                            <div className="p-2 rounded-full bg-[#ad8a56] bg-opacity-10">
                                <Building className="text-[#ad8a56]" size={20} />
                            </div>
                            <h4 className="text-lg font-semibold">Corporate Office</h4>
                        </div>
                        <a
                            href="https://maps.app.goo.gl/ypBKtSAimg1RWFKc8"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="block text-center md:text-start text-gray-600 space-y-2 hover:text-[#ad8a56] transition-all duration-300 transform hover:translate-x-2"
                        >
                            <p>B - 315,</p>
                            <p>Sector-105 Noida,</p>
                            <p>Uttar Pradesh, Pincode - 201304</p>
                        </a>
                    </motion.div>

                    <motion.div variants={itemVariants} className="space-y-8 flex md:block flex-col items-center">
                    <motion.div variants={itemVariants} className="space-y-4 flex md:block flex-col items-center">
                        
                        <div className="flex space-x-4">
                            <a
                                href="https://www.instagram.com/chailheightsvillas/"
                                target="_blank"
                                rel="noreferrer"
                                className="p-2 rounded-full bg-[#ad8a56] bg-opacity-10 hover:bg-opacity-20 transition-all duration-300 group"
                                aria-label="Instagram"
                            >
                                <svg
                                    viewBox="0 0 24 24"
                                    className="w-5 h-5 text-[#ad8a56] group-hover:scale-110 transition-transform duration-300"
                                    fill="currentColor"
                                >
                                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                                </svg>
                            </a>
                            <a
                                href="https://www.facebook.com/chailheightsvillas/"
                                target="_blank"
                                rel="noreferrer"
                                className="p-2 rounded-full bg-[#ad8a56] bg-opacity-10 hover:bg-opacity-20 transition-all duration-300 group"
                                aria-label="Facebook"
                            >
                                <svg
                                    viewBox="0 0 24 24"
                                    className="w-5 h-5 text-[#ad8a56] group-hover:scale-110 transition-transform duration-300"
                                    fill="currentColor"
                                >
                                    <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                                </svg>
                            </a>
                            <a
                                href="https://www.linkedin.com/company/the-shubham-group1/"
                                target="_blank"
                                rel="noreferrer"
                                className="p-2 rounded-full bg-[#ad8a56] bg-opacity-10 hover:bg-opacity-20 transition-all duration-300 group"
                                aria-label="LinkedIn"
                            >
                                <svg
                                    viewBox="0 0 24 24"
                                    className="w-5 h-5 text-[#ad8a56] group-hover:scale-110 transition-transform duration-300"
                                    fill="currentColor"
                                >
                                    <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                                </svg>
                            </a>
                        </div>
                    </motion.div>

                    {/* Contact Info */}
                    <motion.div variants={itemVariants} className="space-y-1 flex md:block flex-col items-center">
                        <div className="flex items-center space-x-2 ">
                            <div className="p-2 rounded-full bg-[#ad8a56] bg-opacity-10">
                                <Mail className="text-[#ad8a56]" size={20} />
                            </div>
                            <h4 className="text-lg font-semibold">Contact Us</h4>
                        </div>
                        <div className="space-y-2">
                            <a
                                href="mailto:info@theshubhamgroup.com"
                                className="block text-center md:text-start hover:text-[#ad8a56] transition-all duration-300 transform hover:translate-x-2"
                            >
                                info@theshubhamgroup.com
                            </a>
                            <a
                                href="tel:+918510850101"
                                className="block text-center md:text-start hover:text-[#ad8a56] transition-all duration-300 transform hover:translate-x-2"
                            >
                                85 10 85 01 01
                            </a>
                        </div>
                    </motion.div>
                    </motion.div>
                </motion.div>

                {/* Bottom Bar */}
                <motion.div
                    variants={itemVariants}
                    className="border-t border-gray-200 mt-5 py-3 text-center text-gray-600"
                >
                    
                    <p className="text-sm">
                        © {new Date().getFullYear()} Chail Heights Villas. All rights reserved.
                    </p>
                </motion.div>
            </div>
        </footer>
    );
}

export default Footer;