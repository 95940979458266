import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { RiPhoneLine } from 'react-icons/ri';
import { IoPersonOutline } from 'react-icons/io5';
import axios from 'axios';
import {X} from 'lucide-react';



function Form( {setShowPopup}) {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
      });
      const [isSubmitting, setIsSubmitting] = useState(false);
      const [showSuccess, setShowSuccess] = useState(false);
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      };
    
      function Close() {
        setShowSuccess(false)
        setShowPopup(false)
      }
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
    
        if (!formData.name || !formData.phone) {
          alert('Please fill in all fields');
          setIsSubmitting(false);
          return;
        }
    
        try {
          await axios.post(
            'https://account.solidperformers.com/leadsapi/data/5b76ccd248fc633df9c8302b89603054',
            formData
          );
          setShowSuccess(true); // Show the success popup
          setFormData({ name: '', phone: '' }); // Reset the form
        } catch (error) {
          console.error('Error submitting form:', error);
          alert('There was an issue submitting the form.');
        } finally {
          setIsSubmitting(false);
        }
      };
  return (
    <>
        <div className="container md:w-[400px] bg-white p-8 w-full mx-auto rounded-2xl relative z-10">
            <div onClick={() => { setShowPopup(false) }} className='p-3 absolute top-5 flex justify-center items-center cursor-pointer right-5 h-[40px] w-[40px] bg-gradient-to-r from-[#ad9b73] to-[#80702F] rounded-full'>
        <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: 'spring', stiffness: 100 }}
            className=""
          >
          <X size={20} className='text-white font-bold' />
        </motion.div>
        </div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center max-w-4xl mx-auto  flex flex-col items-center"
        >
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: 'spring', stiffness: 100 }}
            className="inline-block mb-6"
          >
            <span className="inline-flex items-center px-6 py-2 rounded-full bg-[#ad9b73]/10 text-xl font-medium">
              <span className="animate-pulse mr-2">✨</span>
              Contact Us
            </span>
          </motion.div>
        </motion.div>

        <div className="flex flex-col-reverse md:flex-row w-full gap-12">

          {/* Contact Form */}
          <form onSubmit={handleSubmit} className="space-y-6 w-full">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              className="mb-6 group"
            >
              <label className=" text-[#80702F] text-sm font-medium mb-2 flex items-center gap-2">
                <IoPersonOutline className="text-[#ad9b73]" />
                Full Name
              </label>
              <div className="relative">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-4 py-3 border rounded-lg bg-transparent
                      focus:outline-none focus:ring-1 focus:ring-[#ad9b73] 
                      transition-all duration-300
                      border-[#ad9b73]/30
                      group-hover:border-[#ad9b73]"
                  placeholder="Enter your name"
                  required
                />
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              className="mb-6 group"
            >
              <label className=" text-[#80702F] text-sm font-medium mb-2 flex items-center gap-2">
                <RiPhoneLine className="text-[#ad9b73]" />
                Phone Number
              </label>
              <div className="relative">
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  maxLength={10}
                  className="w-full px-4 py-3 border rounded-lg bg-transparent
                      focus:outline-none focus:ring-1 focus:ring-[#ad9b73] 
                      transition-all duration-300
                      border-[#ad9b73]/30
                      group-hover:border-[#ad9b73]"
                  placeholder="Enter your phone number"
                  required
                />
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <button
                type="submit"
                className={`w-full bg-gradient-to-r from-[#ad9b73] to-[#80702F] 
                    text-white py-3 px-6 rounded-lg transition duration-300
                    hover:shadow-lg hover:shadow-[#ad9b73]/20 cursor-pointer`}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </motion.div>
          </form>
        </div>
      </div>

      {/* Success Popup */}
      {showSuccess && (
        <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            className="bg-white rounded-lg shadow-lg p-6 text-center space-y-4"
          >
            <h3 className="text-2xl font-semibold text-[#ad9b73] yeseva">Thanks!</h3>
            <p className="text-gray-700">Our Executive will contact you soon.</p>
            <button
              onClick={Close}
              className="bg-gradient-to-r from-[#ad9b73] to-[#80702F] text-white px-4 py-2 rounded-lg"
            >
              Close
            </button>
          </motion.div>
        </div>
      )}
    </>
  )
}

export default Form