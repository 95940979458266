import React, { useState, useEffect } from 'react';
import GalleryGrid from "../gallery/GalleryGrid"
import Lightbox from "../gallery/Lightbox"
// import { motion } from 'framer-motion';
import line from '../../line/line.svg';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';


function Gallery({imageslanding}) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [shuffledImages, setShuffledImages] = useState([]);
  const [activeButton, setActiveButton] = useState('all');
  const location = useLocation()
  const landing = location.pathname === '/landing'


  // Fisher-Yates shuffle algorithm
  const shuffleArray = (array) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  // Initialize shuffled images on component mount
  useEffect(() => {
    setShuffledImages(shuffleArray(imageslanding ? imageslanding : allImages));
  }, []);

  // Add useEffect to handle body scroll
  useEffect(() => {
    if (selectedImage) {
      // Prevent scrolling on the background when lightbox is open
      document.body.style.overflow = 'hidden';
    } else {
      // Re-enable scrolling when lightbox is closed
      document.body.style.overflow = 'unset';
    }

    // Cleanup function to ensure we re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [selectedImage]);

  function category(e){
    setActiveButton(e.target.name)
    if(e.target.name === "all"){
      setShuffledImages(allImages)
    }else{
      setShuffledImages(images[e.target.name])
    }
  }

  // Original images array
  const images = {
    view: [
    {
      id: 1,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/1.jpg',
      name: "Churdhar"
    },
    {
      id: 2,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/2.jpg',
      name: "Hassan Valley Green Forest"
    },
    {
      id: 3,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/3.jpg',
      name: "Hassan Valley Green Forest"
    },
    {
      id: 4,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/4.jpg',
      name: "Hassan Valley Green Forest"
    },
    {
      id: 5,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/5.jpg',
      name: "Hassan Valley Green Forest"
    },
    {
      id: 6,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/6.jpg',
    },
    {
      id: 7,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/7.jpg',
      name: "Hassan Valley Green Forest"
    },
    {
      id: 8,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/8.jpg',
      name: "Hassan Valley Green Forest"
    },
    {
      id: 9,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/9.jpg',
      name: "Hassan Valley Green Forest"
    },
    {
      id: 10,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/10.jpg',
    },
    {
      id: 11,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/11.jpg',
    },
    {
      id: 12,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/12.jpg',
    },
    {
      id: 13,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/13.jpg',
      name: "Hassan Valley Green Forest"
    },
    {
      id: 14,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/14.jpg',
    },
    {
      id: 15,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/15.jpg',
    },
  ],
  work: [
    {
      id: 16,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/workinprogress/1.jpg',
    },
    {
      id: 17,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/workinprogress/2.jpg',
    },
    {
      id: 18,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/workinprogress/3.jpg',
    },
    {
      id: 19,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/workinprogress/4.jpg',
    },
    {
      id: 20,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/workinprogress/5.jpg',
    },
    {
      id: 21,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/workinprogress/6.jpg',
    },
    {
      id: 22,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/workinprogress/7.jpg',
    },
    {
      id: 23,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/workinprogress/8.jpg',
    },
    {
      id: 24,
      url: 'https://d2r3u9c7j9d9om.cloudfront.net/gallery/workinprogress/9.jpg',
    },
  ]
}

const allImages = shuffleArray([...images.view, ...images.work]);



  return (
    <div className={`min-h-screen bg-transparent ${landing ?"": "mt-[100px] py-20"}   px-4 md:px-8`}>

      {/* Helmet for SEO */}
      <Helmet>
        <title>Explore Our Luxury Properties Gallery | Chail Heights Villas</title>
        <meta
          name="description"
          content="Browse our stunning photo gallery showcasing the best cottages and villas in Chail, Himachal Pradesh. See the breathtaking views and luxurious amenities that await you"
        />
      </Helmet>

      <div className="max-w-7xl mx-auto relative z-10">
        {/* Gallery Header */}
        <div className="text-center mb-5 flex flex-col items-center justify-center">
          <h1 className="text-5xl md:text-6xl yeseva text-[#ad9b73] font-bold ">
            Gallery
          </h1>
          <img src={line} alt="line" className="w-full h-[40px] opacity-70 ml-[-5px]" />
        </div>

        {/* Category Buttons */}
        {!landing && 
        <div className='w-full flex justify-center mb-8 items-center'>
        <div className='flex flex-wrap gap-5 justify-center'>
          <button
            onClick={(e) => { category(e); }}
            name='all'
            className={`${
              activeButton === 'all'
                ? 'bg-[#ad9b73] border-2 font-bold border-transparent text-white px-4 py-2 rounded-lg'
                : 'bg-transparent text-[#ad9b73] font-bold border-[#ad9b73] border-2 px-4 py-2 rounded-lg'
            }`}
          >
            All
          </button>
          <button
            onClick={(e) => { category(e); }}
            name='view'
            className={`${
              activeButton === 'view'
                ? 'bg-[#ad9b73] border-2 font-bold border-transparent text-white px-4 py-2 rounded-lg'
                : 'bg-transparent text-[#ad9b73] font-bold border-[#ad9b73] border-2 px-4 py-2 rounded-lg'
            }`}
          >
            View From Site
          </button>
          <button
            onClick={(e) => { category(e); }}
            name='work'
            className={`${
              activeButton === 'work'
                ? 'bg-[#ad9b73] border-2 font-bold border-transparent text-white px-4 py-2 rounded-lg'
                : 'bg-transparent text-[#ad9b73] font-bold border-[#ad9b73] border-2 px-4 py-2 rounded-lg'
            }`}
          >
            Work In Progress
          </button>
        </div>
      </div>
      }

        {/* Gallery Grid */}
        <GalleryGrid
          images={shuffledImages}
          onImageSelect={(image, index) => {
            setSelectedImage(image);
            setCurrentIndex(index);
          }}
        />
      </div>

      {/* Lightbox */}
      {selectedImage && (
        <>
          <Lightbox
            image={selectedImage}
            onClose={() => setSelectedImage(null)}
            currentIndex={currentIndex}
            totalImages={shuffledImages.length}
            onNavigate={direction => {
              const newIndex = direction === 'next'
                ? (currentIndex + 1) % shuffledImages.length
                : (currentIndex - 1 + shuffledImages.length) % shuffledImages.length;
              setSelectedImage(shuffledImages[newIndex]);
              setCurrentIndex(newIndex);
            }}
          />
        </>
      )}
    </div>
  );
}

export default Gallery;