import React, { useEffect } from 'react';

function Lightbox({ image, onClose, currentIndex, totalImages, onNavigate }) {
  // Add keyboard event handler
  useEffect(() => {
    const handleKeyDown = (e) => {
      switch (e.key) {
        case 'ArrowLeft':
          onNavigate('prev');
          break;
        case 'ArrowRight':
          onNavigate('next');
          break;
        case 'Escape':
          onClose();
          break;
        default:
          break;
      }
    };

    // Add event listener when component mounts
    window.addEventListener('keydown', handleKeyDown);

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onNavigate, onClose]);

  return (
    <div 
      className="fixed inset-0 backdrop-blur-md bg-black/30 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div 
        className="relative max-w-7xl mx-auto p-4 w-full h-full flex flex-col items-center justify-center"
        onClick={e => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white/70 hover:text-white text-4xl transition-colors z-50"
        >
          ×
        </button>

        <div className="relative w-full h-full flex items-center justify-center">
          <button
            onClick={() => onNavigate('prev')}
            className="absolute left-4 text-white/70 hover:text-white text-6xl transition-colors transform hover:scale-110"
          >
            ‹
          </button>
          
          <img
            src={image.url}
            alt={image.title}
            className="object-contain max-h-[85vh] max-w-[85vw] rounded-lg shadow-2xl"
          />

          <button
            onClick={() => onNavigate('next')}
            className="absolute right-4 text-white/70 hover:text-white text-6xl transition-colors transform hover:scale-110"
          >
            ›
          </button>
        </div>
        
        <div className="absolute bottom-3 left-1/2 -translate-x-1/2 text-center">
          <h3 className="text-white text-2xl font-bold mb-2">{image.title}</h3>
          <p className="text-white/70">
            {image.name}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Lightbox; 