import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import line from '../../line/line.svg';


function Announcement() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });

  return (
    <section ref={ref} className="relative pt-24 overflow-hidden">
      <motion.div 
        className="max-w-5xl mx-auto px-6 relative"
        style={{
          transform: isInView ? "none" : "translateY(100px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }}
      >
        <div className="text-center">
          <h2 className="text-4xl md:text-5xl yeseva text-[#ad9b73]">
            Welcome to Chail Heights Villas
          </h2>
          <img src={line} alt="line" className="w-full h-[50px] md:h-[70px] mt-[-2px] opacity-70 ml-[-5px] mb-3" /> 


          
          <div className="space-y-6 max-w-3xl mx-auto">
            <p className="text-xl text-gray-700 leading-relaxed">
              A prestigious residential gated society featuring
              <span className="font-semibold"> 14 independent villas </span>
              and
              <span className="font-semibold"> 48 1BHK </span>
              ultra luxurious apartments.
            </p>
            
            <div className="relative">
              <p className="text-lg text-gray-700 leading-relaxed relative">
                Developed by 
                <span className="font-semibold text-[#80702F]"> The Shubham Group</span>,
                renowned for successfully delivering five residential projects across
                Uttarakhand and Himachal Pradesh. Strategically located in Chail,
                Himachal Pradesh, near the historic Chail Palace.
              </p>
            </div>
          </div>
        </div>
        <div className='flex flex-col my-12 items-center'>
          <p className="text-5xl md:text-6xl font-bold text-[#ad9b73] text-center yeseva">Rera Certified</p>
          <img src={line} alt="decorative line" className="h-[50px] md:h-[60px] w-full md:w-auto mt-[-5px] mb-5 opacity-80 object-left" />
          <p className="text-3xl text-gray-600 ">HPRERASHI2025123/P</p>
        </div>
      </motion.div>
        
    </section>
  );
}

export default Announcement; 
