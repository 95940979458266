import React from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import line from '../../line/line.svg';


const BloomingProject = () => {
  const containerRef = React.useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end start"]
  });

  const scale = useTransform(scrollYProgress, [0, 0.5], [0.2, 1]);
  const opacity = useTransform(scrollYProgress, [0, 0.3], [0, 1]);
  // const rotate = useTransform(scrollYProgress, [0, 0.5], [45, 0]);

  return (
    <div ref={containerRef} className=" bg-transparent mb relative">
      {/* Header */}
      <motion.div 
        className="text-center pt-20 md:pt-0"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <h1 className="text-5xl md:text-6xl font-bold text-[#ad9b73] yeseva">Project Offering</h1>
        <img src={line} alt="decorative line" className="w-full h-[50px] md:h-[60px] mt-[-5px] mb-16 opacity-80" />

        <div className="w-24 h-1 bg-gold-500 mx-auto"></div>
      </motion.div>

      {/* Blooming Images Container */}
      <div className="flex flex-col w-[90%] md:flex-row mx-auto justify-center items-center gap-8 px-4">
        {/* Left Image */}
            <motion.div 
          className="relative w-full h-[400px] group"
          style={{ scale, opacity }}
        >
        <a href='/apartment'>
          <motion.div 
            className="w-full h-full overflow-hidden rounded-2xl"
            // style={{ rotate }}
          >
            <img 
              src="https://d2r3u9c7j9d9om.cloudfront.net/apartment.webp"
              alt="Project Feature 1"
              className="w-full h-full object-cover transform transition-transform duration-700 "
            />
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/70 rounded-2xl"></div>
            <motion.div 
              className="absolute bottom-0 left-0 right-0 p-8 text-white"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
            >
              <h3 className="text-2xl font-bold mb-3">1 BHK Apartment</h3>
            </motion.div>
          </motion.div>
        </a>
        </motion.div>

        {/* Right Image */}
        <motion.div 
          className="relative w-full h-[400px] group"
          style={{ scale, opacity }}
        >
          <a href='/duplexvilla'>
          <motion.div 
            className="w-full h-full overflow-hidden rounded-2xl"
            // style={{ rotate: useTransform(scrollYProgress, [0, 0.5], [-45, 0]) }}
          >
            <img 
              src="https://one-mallorca.com/wp-content/uploads/Villas-vistas-a-la-montana-mallorca-web-one-mallorca.webp"
              alt="Project Feature 2"
              className="w-full h-full object-cover transform transition-transform duration-700 "
            />
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/10 rounded-2xl"></div>
            <motion.div 
              className="absolute bottom-0 left-0 right-0 p-8 text-white"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
            >
              <h3 className="text-2xl font-bold mb-3">Duplex Luxurious Villa</h3>
            </motion.div>
          </motion.div>
          </a>
        </motion.div>
      </div>
    </div>
  );
};

export default BloomingProject;