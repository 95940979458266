import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { RiPhoneLine } from 'react-icons/ri';
import { IoPersonOutline } from 'react-icons/io5';
import line from '../line/line.svg';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Reach() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  function Close() {
    setShowSuccess(false)
    navigate("/")
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!formData.name || !formData.phone) {
      alert('Please fill in all fields');
      setIsSubmitting(false);
      return;
    }

    try {
      await axios.post(
        'https://account.solidperformers.com/leadsapi/data/6279aeee6cdda1b5b2e7f820ebd698d9',
        formData
      );
      setShowSuccess(true); // Show the success popup
      setFormData({ name: '', phone: '' }); // Reset the form
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('There was an issue submitting the form.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="relative min-h-screen pt-[80px] mb-24">
      {/* Helmet for SEO */}
      <Helmet>
        <title>Contact Us For Luxury Property in Chail Himachal</title>
        <meta
          name="description"
          content="Get in touch with us to buy your dream cottage or villa in Chail, Himachal Pradesh. We offer premium properties and are here to help you plan your dream home in hills"
        />
      </Helmet>

      <div
        className="absolute inset-0 bg-gradient-to-b from-transparent via-[#80702F]/5 to-transparent 
        opacity-50"
      />

      <div className="container mx-auto px-4 mt-16 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center max-w-4xl mx-auto  flex flex-col items-center"
        >
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: 'spring', stiffness: 100 }}
            className="inline-block mb-6"
          >
            <span className="inline-flex items-center px-6 py-2 rounded-full bg-[#ad9b73]/10 text-sm font-medium">
              <span className="animate-pulse mr-2">✨</span>
              Get in Touch
            </span>
          </motion.div>

          <h2 className="text-5xl md:text-6xl font-bold yeseva text-[#ad9b73]">
            Contact Us
          </h2>

          <img
            src={line}
            alt="line"
            className="w-full h-[50px] mt-[-2px] opacity-70 ml-[-5px] mb-12"
          />
        </motion.div>

        <div className="flex flex-col-reverse md:flex-row w-full gap-12">
          {/* Map */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="space-y-8 w-full"
          >
            <div
              className="h-[400px] rounded-lg overflow-hidden shadow-lg 
              relative group transition-all duration-700"
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3442.8281951735616!2d77.22821431449348!3d30.968885381764154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzDCsDU4JzA4LjAiTiA3N8KwMTMnNTAuOCJF!5e0!3m2!1sen!2sin!4v1655300287058!5m2!1sen!2sin"
                width="100%"
                title="Google Map"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="transition-transform duration-700"
              />
            </div>
          </motion.div>

          {/* Contact Form */}
          <form onSubmit={handleSubmit} className="space-y-6 w-full">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              className="mb-6 group"
            >
              <label className=" text-[#80702F] text-sm font-medium mb-2 flex items-center gap-2">
                <IoPersonOutline className="text-[#ad9b73]" />
                Full Name
              </label>
              <div className="relative">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-4 py-3 border rounded-lg bg-transparent
                      focus:outline-none focus:ring-1 focus:ring-[#ad9b73] 
                      transition-all duration-300
                      border-[#ad9b73]/30
                      group-hover:border-[#ad9b73]"
                  placeholder="Enter your name"
                  required
                />
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              className="mb-6 group"
            >
              <label className=" text-[#80702F] text-sm font-medium mb-2 flex items-center gap-2">
                <RiPhoneLine className="text-[#ad9b73]" />
                Phone Number
              </label>
              <div className="relative">
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  maxLength={10}
                  className="w-full px-4 py-3 border rounded-lg bg-transparent
                      focus:outline-none focus:ring-1 focus:ring-[#ad9b73] 
                      transition-all duration-300
                      border-[#ad9b73]/30
                      group-hover:border-[#ad9b73]"
                  placeholder="Enter your phone number"
                  required
                />
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <button
                type="submit"
                className={`w-full bg-gradient-to-r from-[#ad9b73] to-[#80702F] 
                    text-white py-3 px-6 rounded-lg transition duration-300
                    hover:shadow-lg hover:shadow-[#ad9b73]/20 cursor-pointer`}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </motion.div>
          </form>
        </div>
      </div>

      {/* Success Popup */}
      {showSuccess && (
        <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            className="bg-white rounded-lg shadow-lg p-6 text-center space-y-4"
          >
            <h3 className="text-2xl font-semibold text-[#ad9b73] yeseva">Thanks!</h3>
            <p className="text-gray-700">Our Executive will contact you soon.</p>
            <button
              onClick={Close}
              className="bg-gradient-to-r from-[#ad9b73] to-[#80702F] text-white px-4 py-2 rounded-lg"
            >
              Close
            </button>
          </motion.div>
        </div>
      )}
    </section>
  );
}

export default Reach;
